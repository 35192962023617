import { styled } from "styled-components";

import { MOBILE_BREAKPOINT } from "@rimo/ui-old";
import { Accordion } from "./Accordion";

const questions = [
  {
    question: "What kind of articles can Rimo write?",
    answer:
      "Rimo can write anything. Just tell the AI what you need during the initial proofing. For instance, it can craft business insights, technology news or even your personal new year resolutions.",
  },
  {
    question: "Can I customize the article's tone and style?",
    answer:
      "Yes, you can specify this during the initial briefing or instruct the AI for adjustments after the draft is written.",
  },
  {
    question: "How fast can I expect my articles?",
    answer:
      "About 30 minutes to 1 hour. If you streamline the interview during editing, it's possible even in 10 minutes.",
  },
];

export const FAQSection = () => {
  return (
    <Container id="faq">
      <Title>Frequently Asked Questions</Title>
      <Questions>
        {questions.map(({ question, answer }) => (
          <Accordion question={question} answer={answer} key={question} />
        ))}
      </Questions>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  background: #f6fafc;
  padding: 4rem var(--basic-padding);

  @media (width <= ${`${MOBILE_BREAKPOINT}px`}) {
    padding: 4rem 1rem;
  }
`;

const Title = styled.div`
  font-size: 1.125rem;
  font-weight: 700;
  line-height: normal;

  @media (width <= ${`${MOBILE_BREAKPOINT}px`}) {
    text-align: center;
  }
`;

const Questions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
