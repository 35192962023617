import { useClientTranslation } from "@rimo/i18n/useClientTranslation";
import type { ReactNode } from "react";
import { styled } from "styled-components";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type Props = {
  question: string;
  answer: ReactNode;
};

export const Accordion = ({ question, answer }: Props) => {
  const { customT } = useClientTranslation();
  return (
    <Details>
      <Summary>
        {customT(question)}
        <ExpandMoreIcon htmlColor="#444" />
      </Summary>
      <Answer>{customT(answer)}</Answer>
    </Details>
  );
};

const Details = styled.details`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.5rem 2rem;
  border-radius: 1rem;
  box-shadow: 0px 16px 32px -16px rgba(0, 0, 0, 0.08);
  background: #fff;
  cursor: pointer;

  &[open] {
    gap: 1rem;

    > summary > svg {
      transform: rotate(180deg);
    }
  }
`;

const Summary = styled.summary`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #222;
  font-size: 1.25rem;
  font-weight: 500;
  font-weight: 700;

  &::-webkit-details-marker {
    display: none;
  }
`;

const Answer = styled.div`
  color: #444;
  font-size: 1.125rem;
  line-height: normal;
  font-weight: 400;
  margin-top: 1rem;
  line-height: 1.5rem;
`;
