import React from "react";
import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "@rimo/ui-old";

const VideoSection = () => (
  <VideoSectionWrapper>
    <Video src="https://www.youtube.com/embed/O2mOb-mwIJ0?si=_Rk414S1WcpATwJm" maxWidth="787px" />
  </VideoSectionWrapper>
);

export const Video: React.FC<{ src: string; maxWidth: string }> = ({ src, maxWidth }) => (
  <VideoWrapper $maxWidth={maxWidth}>
    <IframeWrapper $maxWidth={maxWidth}>
      <VideoIframe
        src={src}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </IframeWrapper>
  </VideoWrapper>
);

const VideoSectionWrapper = styled.div`
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 3rem;
  }
`;

const VideoWrapper = styled.div<{ $maxWidth: string }>`
  max-width: ${({ $maxWidth }) => $maxWidth};
  margin: 0 auto;
`;

const IframeWrapper = styled.div<{ $maxWidth: string }>`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 アスペクト比 */
  padding-top: 25px;
  height: 0;
  max-width: ${({ $maxWidth }) => $maxWidth};
`;

const VideoIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 0.5rem;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    border-radius: 0;
  }
`;

export default VideoSection;
