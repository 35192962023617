import Link from "next/link";
import { useState } from "react";
import { styled } from "styled-components";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Drawer } from "@mui/material";
import { Constant } from "@rimo/frontend/constants/constant";
import { useSearchParamsForWritingMode } from "./Header";

export const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const params = useSearchParamsForWritingMode();

  const toggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <>
      <MenuButton type="button" aria-label="open menu" onClick={toggleOpen} $open={isOpen}>
        {isOpen ? <CloseIcon color="inherit" /> : <MenuIcon color="inherit" />}
      </MenuButton>
      {isOpen && (
        <Drawer anchor="top" open={isOpen} onClose={toggleOpen}>
          <MenuWrapper>
            <HeaderMenu>
              <Nav aria-label="header navigation">
                <List>
                  <LinkItem>
                    <a href="#features" onClick={toggleOpen}>
                      Features
                    </a>
                  </LinkItem>
                  <LinkItem>
                    <a href="#pricing" onClick={toggleOpen}>
                      Pricing
                    </a>
                  </LinkItem>
                  <LinkItem>
                    <a href="#faq" onClick={toggleOpen}>
                      FAQ
                    </a>
                  </LinkItem>
                </List>
              </Nav>
              <Separator />
              <LinkList>
                <StyledLink href={`${Constant.Url.AIEditor.Head}${params}`}>
                  Login
                  <KeyboardArrowRightIcon />
                </StyledLink>
                <StyledLink href={`${Constant.Url.AIEditor.Head}${params}`}>
                  Get Started
                  <KeyboardArrowRightIcon />
                </StyledLink>
              </LinkList>
            </HeaderMenu>
          </MenuWrapper>
        </Drawer>
      )}
    </>
  );
};

const MenuButton = styled.button<{ $open: boolean }>`
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16.25rem;
  border: none;
  cursor: pointer;
  display: grid;
  place-content: center;
  width: 2.5rem;
  height: 2.5rem;
  position: relative;
  z-index: 2000;

  color: ${({ $open }) => ($open ? "#222" : "#fff")};
`;

const MenuWrapper = styled.div`
  background: #fff;
  color: #222;
`;

const HeaderMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 4rem 1rem 1rem;
`;

const Nav = styled.nav`
  width: 100%;
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const LinkItem = styled.li`
  font-weight: 500;
  line-height: normal;
`;

const LinkList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
  line-height: normal;
`;

const Separator = styled.hr`
  border: 0.5px solid rgb(221, 221, 221);
  width: 100%;
`;
