import Link from "next/link";
import { styled } from "styled-components";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { MOBILE_BREAKPOINT } from "@rimo/ui-old";
import { useSearchParamsForWritingMode } from "./Header";
import { Constant } from "@rimo/frontend/constants/constant";
import { Video } from "@rimo/frontend/pages/AIEditorLP/VideoSection";

export const CTA = () => {
  const params = useSearchParamsForWritingMode();
  const linkHref = `${Constant.Url.AIEditor.Head}${params}`;

  return (
    <Wrapper>
      <Background />
      <Container>
        <SubVideo>
          <Video src="https://www.youtube.com/embed/O2mOb-mwIJ0?si=_Rk414S1WcpATwJm" maxWidth="540px" />
        </SubVideo>
        <Description>
          <div>
            <p>Experience the Future of Content Creation.</p>
            <p>Start Your Free Trial Today!</p>
          </div>
          <LinkButton href={linkHref}>
            Get Started
            <KeyboardArrowRightIcon />
          </LinkButton>
        </Description>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  margin-top: 9.81rem;

  @media (width <= ${`${MOBILE_BREAKPOINT}px`}) {
    overflow: hidden;
    margin-top: 4.62rem;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5.12rem;
  padding-inline: 5.44rem;

  @media (max-width: 1000px) {
    gap: 2.25rem;
    padding-inline: 4rem;
  }

  @media (width <= ${`${MOBILE_BREAKPOINT}px`}) {
    flex-direction: column-reverse;
    padding: 3rem 0.75rem 0;
    height: 100%;
  }
`;

const Background = styled.div`
  background-image: url("/assets/about_ai_editor/background.png");
  background-size: cover;
  clip-path: polygon(0 25%, 100% 0, 100% 100%, 0 100%);
  width: 100%;
  height: 20rem;
  position: absolute;
  top: 0;
  z-index: -1;
  min-height: 330px;

  @media (width <= ${`${MOBILE_BREAKPOINT}px`}) {
    clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 100%);
    height: 100%;
  }
`;

const SubVideo = styled.div`
  width: 100%;
  max-width: 540px;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.38rem;

  @media (width <= ${`${MOBILE_BREAKPOINT}px`}) {
    gap: 0.88rem;
  }

  > div {
    > p:first-of-type {
      color: rgba(0, 0, 0, 0.65);
      font-size: 1.375rem;
      font-weight: 700;
      line-height: normal;
      text-align: center;

      @media (width <= ${`${MOBILE_BREAKPOINT}px`}) {
        font-size: 1.0625rem;
      }
    }

    > p:nth-of-type(2) {
      color: rgba(255, 255, 255, 0.95);
      text-align: center;
      font-size: 2.1875rem;
      font-weight: 700;
      line-height: normal;
    }

    @media (width <= ${`${MOBILE_BREAKPOINT}px`}) {
      font-size: 1.6875rem;
    }
  }
`;

const LinkButton = styled(Link)`
  display: inline-flex;
  width: fit-content;
  overflow: hidden;
  padding: 0.5rem 2rem;
  border-radius: 7rem;
  color: #fff;
  font-weight: 700;
  line-height: 2.31;
  text-wrap: nowrap;
  background: #f14c00;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;

  @media (width <= ${`${MOBILE_BREAKPOINT}px`}) {
    marin-top: 2rem;
  }
`;
