import { styled } from "styled-components";

import { MOBILE_BREAKPOINT } from "@rimo/ui-old";

export const AIEditorBackground = styled.div`
  background-image: url("/assets/about_ai_editor/background.png");
  background-size: cover;
  clip-path: polygon(0 0%, 100% 0, 100% 40%, 0 100%);
  width: 100%;
  height: 25rem;
  position: absolute;
  top: 0;
  z-index: -1;

  > * {
    display: none;
  }

  @media (width <= ${`${MOBILE_BREAKPOINT}px`}) {
    clip-path: polygon(0 0%, 100% 0, 100% 80%, 0 100%);
    height: 28rem;
    overflow: hidden;

    > * {
      display: flex;
      justify-content: flex-end;
    }
  }
`;
