import Link from "next/link";
import Image from "next/image";
import { styled } from "styled-components";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { MOBILE_BREAKPOINT } from "@rimo/ui-old";
import { HamburgerMenu } from "./HamburgerMenu";
import { useSearchParamsString } from "@rimo/frontend/hooks/useNavigation";
import { Constant } from "@rimo/frontend/constants/constant";
import { useState, useEffect } from "react";
import { useParams } from "next/navigation";

export const useSearchParamsForWritingMode = () => {
  const searchParams = useSearchParamsString();
  const [enhancedSearchParamsString, setEnhancedSearchParamsString] = useState("");

  useEffect(() => {
    const enhancedParams = new URLSearchParams(searchParams);
    enhancedParams.set("only_writing", "true");
    enhancedParams.set("utm_content", "ai_editor_lp");
    setEnhancedSearchParamsString(enhancedParams.toString() ? "?" + enhancedParams.toString() : "");
  }, [searchParams]);

  return enhancedSearchParamsString;
};

export const Header = () => {
  const params = useSearchParamsForWritingMode();
  const localeParams = useParams();
  const currentLocale = localeParams?.lng ?? "en";

  return (
    <StyledHeader>
      <HeaderMenu>
        <Link href={`/${currentLocale}/about/ai-editor`}>
          <Image src="/assets/about_ai_editor/rimo_logo_white.svg" alt="Rimo, Inc." width={73} height={24} />
        </Link>
        <Nav aria-label="header navigation">
          <List>
            <LinkItem>
              <a href={`/${currentLocale}/about/ai-editor#features`}>Features</a>
            </LinkItem>
            <LinkItem>
              <a href={`/${currentLocale}/about/ai-editor#pricing`}>Pricing</a>
            </LinkItem>
            <LinkItem>
              <a href={`/${currentLocale}/about/ai-editor#faq`}>FAQ</a>
            </LinkItem>
          </List>
        </Nav>
      </HeaderMenu>
      <LinkList>
        <StyledLink href={`${Constant.Url.AIEditor.Head}${params}`}>
          Login
          <KeyboardArrowRightIcon />
        </StyledLink>
        <StyledLink href={`${Constant.Url.AIEditor.Head}${params}`}>
          Get Started
          <KeyboardArrowRightIcon />
        </StyledLink>
      </LinkList>
      <MobileMenu>
        <HamburgerMenu />
      </MobileMenu>
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: 1.5rem;
  padding-inline: clamp(1.5rem, (1rem + 10vw), 7rem);

  @media (width <= ${`${MOBILE_BREAKPOINT}px`}) {
    padding: 1rem;
  }
`;

const HeaderMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`;

const Nav = styled.nav`
  @media (width <= ${`${MOBILE_BREAKPOINT}px`}) {
    display: none;
  }
`;

const List = styled.ul`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

const LinkItem = styled.li`
  color: #fff;
  font-weight: 500;
  line-height: normal;
`;

const LinkList = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  @media (width <= ${`${MOBILE_BREAKPOINT}px`}) {
    display: none;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  padding: 0.25rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: normal;

  &:hover {
    border-radius: 7rem;
    background: rgba(255, 255, 255, 0.5);
  }
`;

const MobileMenu = styled.div`
  @media (width >= ${`${MOBILE_BREAKPOINT}px`}) {
    display: none;
  }
`;
