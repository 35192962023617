"use client";

import { Header } from "./Header";
import { KeyVisual } from "./KeyVisual";
import { FeaturesSection } from "./Features";
import { PricingSection } from "./Pricing";
import { CTA } from "./CTA";
import { FAQSection } from "./FAQ";
import { Footer } from "./Footer";
import { Usecases } from "./Usecases";
import VideoSection from "./VideoSection";
import styled from "styled-components";

import { AIEditorBackground } from "./Background";
import { MOBILE_BREAKPOINT } from "@rimo/ui-old";
import Image from "next/image";

export const AIEditorLP = () => {
  return (
    <>
      <Header />
      <AIEditorBackground>
        <MacBookImage src="/assets/about_ai_editor/macbook_light.png" width={602} height={484} alt="" />
        <IPhoneImage src="/assets/about_ai_editor/iphone_14_promax.png" width={218} height={444} alt="" />
      </AIEditorBackground>
      <AllWrapper>
        <KeyVisual />
        <VideoSection />
        <FeaturesSection />
        <Usecases />
        <PricingSection />
        <CTA />
        <FAQSection />
      </AllWrapper>
      <Footer />
    </>
  );
};

// max-width: 1400px;
//   margin: 0 auto;

const AllWrapper = styled.main`
  --lp-max-width: 1200px;
  --basic-padding: max(calc(max(calc(100vw - var(--lp-max-width)), 0px) / 2), 4rem);
`;

const MacBookImage = styled(Image)`
  @media (width <= ${`${MOBILE_BREAKPOINT}px`}) {
    margin: 0;
    position: absolute;
    top: 5.5rem;
    left: 50%;
  }
`;

const IPhoneImage = styled(Image)`
  position: absolute;
  top: 3.57rem;
  left: -3.47rem;
  border-radius: 2.4rem;

  box-shadow: 10px 0px 30px rgba(242, 145, 64, 0.5);

  @media (width <= ${`${MOBILE_BREAKPOINT}px`}) {
    top: 8.5rem;
    left: 50%;
    transform: translateX(-75%);
  }
`;
