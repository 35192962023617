import Image from "next/image";
import Link from "next/link";
import styled from "styled-components";

import { MOBILE_BREAKPOINT } from "@rimo/ui-old";
import { useLocaleContext } from "../../contexts/LocaleProvider";

export const Footer = () => {
  const { locale } = useLocaleContext();
  return (
    <StyledFooter>
      <LogoWrapper>
        <Image src="/assets/rimo_logo.svg" alt="Rimo, Inc." width={105} height={35} />
        <CopyLight>&copy; 2023 Rimo, Inc.</CopyLight>
      </LogoWrapper>
      <div>
        <nav aria-label="footer navigation">
          <NavigationList>
            <LinkItem>
              <a href="#features">Features</a>
            </LinkItem>
            <LinkItem>
              <a href="#pricing">Pricing</a>
            </LinkItem>
            <LinkItem>
              <a href="#faq">FAQ</a>
            </LinkItem>
            <LinkItem>
              <Link href={locale === "ja" ? "/policies/privacy" : `/global_policies/privacy`}>Privacy Policy</Link>
            </LinkItem>
            <LinkItem>
              <Link href={locale === "ja" ? "/policies/terms" : `/global_policies/terms`}>Terms of Use</Link>
            </LinkItem>
          </NavigationList>
        </nav>
      </div>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2.5rem;
  padding: 4rem 7rem;

  @media (width <= ${`${MOBILE_BREAKPOINT}px`}) {
    padding: 4rem 1rem;
    flex-direction: column-reverse;
  }
`;

const NavigationList = styled.ul`
  display: flex;
  align-items: center;
  column-gap: 2.5rem;

  @media (width <= ${`${MOBILE_BREAKPOINT}px`}) {
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 1.5rem;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const CopyLight = styled.div`
  color: #888;
  font-size: 0.8125rem;
  line-height: normal;
`;

const LinkItem = styled.li`
  color: #666;
  font-weight: 500;
  line-height: normal;
`;
