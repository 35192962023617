import { useClientTranslation } from "@rimo/i18n/useClientTranslation";
import Image from "next/image";
import { styled } from "styled-components";

import { MOBILE_BREAKPOINT } from "@rimo/ui-old";

const features = [
  {
    label: "Step 1",
    title: "Get Set, Plan!",
    description:
      "Before diving into writing, let Rimo understand your goals. Whether it's a press release or a report, just outline your needs, and we'll craft the perfect framework for your content.",
  },
  {
    label: "Step 2",
    title: "Let's Talk",
    description:
      "Simply talk to Rimo's AI as if you're having a friendly chat. Rimo listens and asks questions, tailoring the conversation to ensure all key details are captured for your content. No need to worry about perfect wording—just speak freely.",
  },
  {
    label: "Step 3",
    title: "Instant Drafts",
    description:
      "Once the conversation ends, Rimo instantly generates a draft. This initial version is your canvas — feel free to tweak and tailor it to your heart's content.",
  },
  {
    label: "Step 4",
    title: "Customize Your Content",
    description:
      "Want to add your personal touch or adjust the format? No problem. Direct Rimo to refine the entire document or just polish specific parts. Make it casual, add details, or even translate—leave the heavy lifting to us.",
  },
  {
    label: "Tip",
    title: "Upload and Go",
    description:
      "Have a recorded interview? Simply upload the audio, and Rimo will transform it into a structured article in a few minutes. It's your fast track to content creation.",
  },
] as const;

export const FeaturesSection = () => {
  const { customT } = useClientTranslation();
  return (
    <Features id="features">
      <Heading>How It Works</Heading>
      {features.map((feature, i) => (
        <Feature key={feature.title}>
          <FeatureIndex>{customT(feature.label)}.</FeatureIndex>
          <FeatureTitle>{customT(feature.title)}</FeatureTitle>
          <FeatureDescription>{customT(feature.description)}</FeatureDescription>
          <FeatureImage>
            <MacBookImage
              src={`/assets/about_ai_editor/feature${i + 1}.png`}
              width={468}
              height={304}
              alt={customT(feature.title)}
            />
          </FeatureImage>
        </Feature>
      ))}
    </Features>
  );
};

const Features = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5rem;
  padding-inline: clamp(1.5rem, (1rem + 10vw), 7rem);
  scroll-margin-top: 5rem;
  margin-top: 7rem;
  padding: 0 var(--basic-padding);

  @media (width <= ${`${MOBILE_BREAKPOINT}px`}) {
    row-gap: 3.75rem;
    padding-inline: 1rem;
    margin-top: 4.75rem;
    scroll-margin-top: 2rem;
  }
`;

const Heading = styled.div`
  font-size: 3rem;
  line-height: 4rem;
  text-align: center;
  margin: 1rem 0 2rem;

  background: linear-gradient(45deg, #ffaf00 0%, #e50000 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 2rem;
    line-height: 2.5rem;
    margin: 1rem;
  }
`;

const Feature = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto 1fr;
  grid-column-gap: 4rem;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr 350px;
    grid-column-gap: 2rem;
  }

  @media (width <= ${`${MOBILE_BREAKPOINT}px`}) {
    place-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
  }

  @media (max-width: 500px) {
    place-items: baseline;
  }
`;

const FeatureIndex = styled.p`
  grid-area: 1 / 1 / 2 / 2;
  margin-bottom: 1.25rem;
  background: linear-gradient(225deg, #e50000 -0.12%, #ffaf00 99.9%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: normal;

  @media (width <= ${`${MOBILE_BREAKPOINT}px`}) {
    grid-area: 1 / 1 / 2 / 2;
    margin-bottom: 0.75rem;
  }
`;

const FeatureTitle = styled.h2`
  grid-area: 2 / 1 / 3 / 2;
  margin-bottom: 1rem;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: normal;

  @media (width <= ${`${MOBILE_BREAKPOINT}px`}) {
    grid-area: 2 / 1 / 3 / 2;
    margin-bottom: 1.5rem;
    font-size: 1.625rem;
  }
`;

const FeatureDescription = styled.p`
  grid-area: 3 / 1 / 4 / 2;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: normal;
  line-height: 2rem;

  @media (width <= ${`${MOBILE_BREAKPOINT}px`}) {
    grid-area: 4 / 1 / 5 / 2;
    font-size: 1rem;
    line-height: 1.5rem;
    max-width: 450px;
  }
`;

const FeatureImage = styled.div`
  grid-area: 1 / 2 / 4 / 3;

  @media (max-width: 1000px) {
    max-width: 300px;
  }

  @media (width <= ${`${MOBILE_BREAKPOINT}px`}) {
    grid-area: 3 / 1 / 4 / 2;
    margin-bottom: 1.5rem;
    max-width: none;
  }
`;

const MacBookImage = styled(Image)`
  box-shadow: 0px 0px 3rem rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;

  @media (max-width: 1000px) {
    max-width: 350px;
    height: 227.5px;
    display: flex;
    align-items: center;
  }

  @media (width <= ${`${MOBILE_BREAKPOINT}px`}) {
    box-shadow: 0px 0px 1rem rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 65%;
  }
`;
