import Image from "next/image";
import Link from "next/link";
import { styled } from "styled-components";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useSearchParamsForWritingMode } from "./Header";
import { MOBILE_BREAKPOINT } from "@rimo/ui-old";
import { Constant } from "@rimo/frontend/constants/constant";

export const KeyVisual = () => {
  const params = useSearchParamsForWritingMode();
  return (
    <Container>
      <Title>
        Talk Your Thoughts,
        <br />
        We’ll Craft Your Writing.
      </Title>
      <Description>
        Rimo listens, questions, and writes—transforming your spoken ideas into structured texts, letting you focus on
        what truly matters.
      </Description>
      <LinkButton href={`${Constant.Url.AIEditor.Head}${params}`}>
        Get Started
        <KeyboardArrowRightIcon />
      </LinkButton>
      <TopImage>
        <MacBookImage src="/assets/about_ai_editor/macbook_light.png" width={497} height={423} alt="AIEditor-PC" />
        <IPhoneImage
          src="/assets/about_ai_editor/iphone_14_promax.png"
          width={218}
          height={444}
          alt="AIInterviewer-Mobile"
        />
      </TopImage>
    </Container>
  );
};
//padding-left: clamp(1.5rem, (1rem + 10vw), 7rem);
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 20%;
  grid-template-rows: repeat(3, auto) 140px;
  grid-column-gap: 8.81rem;

  overflow: hidden;
  padding: 0 var(--basic-padding) 0 var(--basic-padding);

  @media (max-width: 1000px) {
    grid-column-gap: 4rem;
  }

  @media (width <= ${`${MOBILE_BREAKPOINT}px`}) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
    grid-column-gap: 0;
    place-content: center;
    padding: 0;
    padding-left: 1.5rem;
  }
`;

const Title = styled.h1`
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.06;
  margin-top: 4.69rem;

  @media (width <= ${`${MOBILE_BREAKPOINT}px`}) {
    font-size: 3.5rem;
    line-height: 1.03;
    margin-top: 21rem;
  }
`;

const Description = styled.p`
  color: #444;
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 1.19;
  text-wrap: balance;
  margin-top: 0.63rem;

  @media (width <= ${`${MOBILE_BREAKPOINT}px`}) {
    font-size: 1.375rem;
    line-height: 1.27;
    margin: 0;
  }
`;

const LinkButton = styled(Link)`
  display: inline-flex;
  overflow: hidden;
  padding: 0.5rem 2rem;
  border-radius: 7rem;
  color: #fff;
  font-weight: 700;
  line-height: 2.31;
  background: #f14c00;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  width: fit-content;
  height: 2.31rem;
  margin-top: 2.81rem;

  @media (width <= ${`${MOBILE_BREAKPOINT}px`}) {
    margin-top: 2rem;
  }
`;

const TopImage = styled.div`
  grid-area: 1 / 2 / 5 / 3;
  position: relative;
  z-index: -1;

  @media (width <= ${`${MOBILE_BREAKPOINT}px`}) {
    display: none;
  }
`;

const MacBookImage = styled(Image)`
  border-radius: 0.5rem;
  position: relative;

  @media (width <= ${`${MOBILE_BREAKPOINT}px`}) {
    margin: 0;
    position: absolute;
    top: 5.5rem;
    right: -27rem;
  }
`;

const IPhoneImage = styled(Image)`
  position: absolute;
  top: 2rem;
  left: 0rem;
  transform: translateX(-80%);
  border-radius: 2.4rem;

  @media (max-width: 1000px) {
    transform: translateX(-30%);
  }

  @media (width <= ${`${MOBILE_BREAKPOINT}px`}) {
    top: 7.5rem;
    right: 3rem;
    left: auto;
  }
`;
